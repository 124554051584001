var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticStyle: { "text-align": "left" } },
    [
      _c("b-card", { staticClass: "mt-4" }, [
        _c(
          "form",
          {
            ref: "accountForm",
            attrs: { action: "" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.save()
              }
            }
          },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", lg: "6" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "First Name" } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            autocomplete: "given-name",
                            placeholder: "First Name",
                            required: "",
                            disabled: _vm.loading
                          },
                          model: {
                            value: _vm.form.firstname,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "firstname", $$v)
                            },
                            expression: "form.firstname"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", lg: "6" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Last Name" } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            autocomplete: "family-name",
                            placeholder: "Last Name",
                            required: "",
                            disabled: _vm.loading
                          },
                          model: {
                            value: _vm.form.lastname,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "lastname", $$v)
                            },
                            expression: "form.lastname"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12", lg: "6" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Email" } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            autocomplete: "email",
                            placeholder: "Email",
                            required: "",
                            disabled: _vm.loading
                          },
                          model: {
                            value: _vm.form.email,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "email", $$v)
                            },
                            expression: "form.email"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", lg: "6" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { label: "Phone" } },
                      [
                        _c("b-form-input", {
                          attrs: {
                            autocomplete: "tel",
                            type: "tel",
                            placeholder: "000-000-0000",
                            pattern:
                              "^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$",
                            required: "",
                            disabled: _vm.loading
                          },
                          model: {
                            value: _vm.form.phonenumber,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "phonenumber", $$v)
                            },
                            expression: "form.phonenumber"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c("hr", {
                      staticStyle: { "border-top": "1px solid white" }
                    }),
                    _c(
                      "b-button",
                      {
                        staticClass: "mb-3",
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            return _vm.togglePasswordEdit()
                          }
                        }
                      },
                      [
                        !_vm.editPassword
                          ? _c(
                              "span",
                              [
                                _c("b-icon-pencil-fill"),
                                _vm._v(" Edit Password")
                              ],
                              1
                            )
                          : _c(
                              "span",
                              [
                                _c("b-icon-x-circle-fill"),
                                _vm._v(" Cancel Password Edit")
                              ],
                              1
                            )
                      ]
                    ),
                    _c(
                      "b-row",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editPassword,
                            expression: "editPassword"
                          }
                        ]
                      },
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12", lg: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "New Password" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "password",
                                    autocomplete: "new-password",
                                    placeholder: "New Password",
                                    disabled: !_vm.editPassword || _vm.loading,
                                    required: "",
                                    minlength: "6"
                                  },
                                  model: {
                                    value: _vm.form.password,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "password", $$v)
                                    },
                                    expression: "form.password"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", lg: "6" } },
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Confirm New Password" } },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    type: "password",
                                    placeholder: "Confirm New Password",
                                    autocomplete: "new-password",
                                    disabled: !_vm.editPassword || _vm.loading,
                                    required: "",
                                    minlength: "6"
                                  },
                                  model: {
                                    value: _vm.form.password_confirm,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "password_confirm",
                                        $$v
                                      )
                                    },
                                    expression: "form.password_confirm"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c("hr"),
                    _c(
                      "b-overlay",
                      { attrs: { show: _vm.loading } },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mb-3",
                            attrs: {
                              block: "",
                              variant: "primary",
                              type: "submit",
                              disabled: _vm.formInvalid() || _vm.loading
                            }
                          },
                          [_vm._v(" Save ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticStyle: { "text-align": "center" },
                    attrs: { cols: "12" }
                  },
                  [_c("small", [_vm._v("*all fields required")])]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }